<template>
    <Panel :toggleable='true'  class='col-12 sidebar-like'>
        <template #header>
            <span v-if='loading'>Loading delle news

            </span>
            <span v-else>Selezione delle notizie degli ultimi <InputNumber
                :use-grouping='false'
                :min='1'
                :max='30'
                suffix=' giorni'
                class='ml-2'
                v-model='howManyDaysBack'
                showButtons buttonLayout='horizontal'
            />
            </span>
        </template>
        <div class='grid'>
            <div class='col-12 md:col-6'>
                <Accordion>
                    <AccordionTab  v-for='thu in rows' :key='"thu_"+thu.id+"_"+lang' >
                        <template #header >
                            <div class='flex justify-content-between align-content-center flex-wrap' style='width: 100%'>
                                <div class='flex align-items-center justify-content-center'>The Update {{dateShort(thu.publish_date)}}</div>
                                <Button
                                    class='flex p-button-sm p-button-info'
                                    :label='(normalizing === thu.id) ? "":"Riordina"'
                                    :icon='(normalizing === thu.id) ? "pi pi-spin pi-spinner":""'
                                    @click.stop='requestNormalization(thu.id)'
                                />
                            </div>
                        </template>
                        <DataTable
                            class='p-datatable-gridlines'
                            lazy
                            :value='nonSelectedRows(thu.id)'
                        >
                            <Column field='title' header='Titolo'>
                                <template #body='slotProps'>
                                    <Chip :class='slotProps.data.type'>{{slotProps.data.type}}</Chip> {{slotProps.data.title}}
                                    <Flag
                                        v-for='bb in slotProps.data.langs'
                                        :key='"o_"+slotProps.data.id+"_"+bb.lang'
                                        :code="bb.lang"  :width='24' :class="{'mr-1': true}" :title='bb.rank+ " - " + bb.title'/>
                                </template>
                            </Column>
                            <Column field='id' style='min-width: 7.5rem'>
                                <template #body='slotProps'>
                                    <Button icon='pi pi-plus' @click='addInLang(thu.id, slotProps.data, lang, dateShort(thu.publish_date))' title='Lingua corrente'></Button>
                                    <Button icon='pi pi-plus' @click='addInLangs(thu.id, slotProps.data, dateShort(thu.publish_date))' class='p-button-warning ml-1' title='Tutte le lingue'></Button>
                                </template>
                            </Column>
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div>
            <div class='col-12 md:col-6 text-center'>
                <DataTable
                    class='p-datatable-gridlines'
                    lazy
                    :value='selected'
                >
                    <Column field='date' header='Data'></Column>
                    <Column field='title' header='Pronti per la generazione'>
                        <template #body='slotProps'>
                            <Chip :class='slotProps.data.type'>{{slotProps.data.type}}</Chip> {{slotProps.data.title}}
                        </template>
                    </Column>
                    <Column field='langs' header='Lingue'>
                        <template #body='slotProps'>
                            <Flag
                                v-for='lang in slotProps.data.langs'
                                :key='"flag_"+slotProps.data.id+"_"+lang'
                                :code="lang"  :width='24' :class="{'mr-1': true}"/>
                        </template>
                    </Column>
                    <Column field='id' style='min-width: 7.5rem'>
                        <template #body='slotProps'>
                            <Button class='p-button-danger' icon='pi pi-minus' @click='delInLang(slotProps.data)' title='Rimuovi'></Button>
                        </template>
                    </Column>
                </DataTable>
                <Button class='p-button-success mt-2' v-if='selected && selected.length' icon='pi pi-save' label='Salva' @click='addToWeekly'/>
            </div>
        </div>
    </Panel>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import tools from '../../../libs/tools';
import Notifications from '../../../mixins/notifications';
const { mapActions, mapGetters } = createNamespacedHelpers('Cont/TheUpdate');
import dd_languages from '../../../mixins/dd_languages';

export default {
    mixins: [Notifications, dd_languages],
    data(){
        return {
            normalizing: 0,
            rows: [],
            xIds: {},
            selected: [],
            howManyDaysBack: 7
        }
    },
    mounted(){
        this.loadNews();
    },
    computed: {
        ...mapGetters(['loading', 'row']),
        nonSelectedRows() {
            return id => {
                return this.xIds[id].filter(x => !x['selected']);
            }
        }
    },
    methods: {
        ...mapActions(['search', 'weeklyCompose', 'normalize']),
        dateShort(d){
            return this.Formatters().time.dateFormat(this.Formatters().time.api2Date(d)).substr(0, 10);
        },
        addToWeekly(){
            const payload = {
                thu_id: this.row.id,
                blocks: []
            };
            const blocks = [];
            this.selected.forEach(block => {
               blocks.push({
                   id: block.id,
                   langs: block.langs,
               })
            });
            payload.blocks = blocks;
            this.weeklyCompose(payload).then(() => {
                this.ShowSuccess("Perfetto", "News inserite con successo")
                this.selected = [];
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        addInLangs(thuId, block, d){
            const langs = [];
            this.dd_languages.forEach(l => {
                langs.push(l.id);
            })
            this.addInLang(thuId, block, langs, d);
        },
        addInLang(thuId, block, lang, d){
            this.xIds[thuId].forEach(b => {
               if (b.id === block.id){
                   b.selected = true;
               }
            });
            const item = {
                ...block,
                the_update_id: thuId,
                date: d,
                langs: []
            };
            if (typeof lang === 'string') {
                item.langs.push(lang);
            } else if (typeof lang === 'object') {
                item.langs.push(...lang);
            }
            this.selected.push(item)
        },
        delInLang(block){
            this.xIds[block.the_update_id].forEach(b => {
                if (b.id === block.id){
                    b.selected = false;
                }
            });
            this.selected = this.selected.filter(b => b['id'] !== block.id);
        },
        sortBlocks(translation){
            if (!translation.blocks) return [];
            if (!translation.sections) return translation.blocks;
            const sorted = [];
            translation.sections.forEach(sec => {
                translation.blocks.forEach(block => {
                    if (block.type===sec.section){
                        sorted.push({
                            ...block
                        });
                    }
                });
            });
            return sorted;
        },
        parseTheUpdateRow(r){
            this.xIds[r['id']] = []; //Le righe della tabella in una lingua
            if (r['translations']){
                const ranks = {};
                r['translations'].forEach(t => {
                    if (t['lang'] === this.lang){
                        //Sort blocks by section/rank
                        const sorted = this.sortBlocks(t);
                        if (!this.xIds[r['id']]) this.xIds[r['id']] = [];
                        this.xIds[r['id']].push(...sorted)
                    } else {
                        if (t['blocks']){
                            t['blocks'].forEach(b => {
                                if (!ranks[b.type+b.rank]) ranks[b.type+b.rank] = [];
                                b.lang = t['lang'];
                                ranks[b.type+b.rank].push({
                                    lang: b.lang,
                                    rank: b.rank,
                                    title: b.title
                                });
                            })
                        }
                    }
                });

                if (this.xIds[r['id']]){
                    this.xIds[r['id']].forEach(b => {
                        if (ranks[b.type+b.rank]){
                            b.langs = ranks[b.type+b.rank];
                        } else {
                            b.langs = [];
                        }
                    });
                }
            }
        },
        requestNormalization(id){
            this.normalizing = id;
            this.normalize(id).then(res => {
                this.parseTheUpdateRow(res);
            }).catch(err => {
                this.ShowStandardError(err.response);
            }).finally(() => {
                this.normalizing = 0;
            });
        },
        loadNews(){
            this.rows = [];
            this.xIds = {};
            this.selected = [];
            const fd = new Date(this.parent_date.getTime() - (this.howManyDaysBack * 24 * 60 * 60 * 1000));
            const td = new Date(this.parent_date.getTime() - (1 * 60 * 60 * 1000));
            const payload = {
              page: 1,
              limit: 0,
              date_to: tools.time.toEnglish(td),
              date_from:  tools.time.toEnglish(fd),
              full: true
            };
            this.search(payload).then(thus => {
                this.rows = thus['the_updates'];
                this.rows.forEach(r => {
                    this.parseTheUpdateRow(r);
                })
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        }
    },
    props: {
        model: {
            type: Object,
            default() {
                return {
                    blocks: [],
                    sections: [],
                };
            },
            required: true,
        },
        lang: {
            type: String,
            default: 'it'
        },
        parent_date: {
            type: Date,
            default(){
                return new Date();
            }
        }
    },
    watch: {
        'model.id'() {
            this.loadNews();
        },
        howManyDaysBack(){
            this.loadNews();
        },
        lang(){
            this.selected = [];
        },
        parent_date(n, o){
            if (n.getTime() !== o.getTime()){
                this.loadNews();
            }
        }
    }
}
</script>

<style lang='scss'>
.p-chip.tools {
    background: var(--blue-200);
}
.p-chip.news {
    background: var(--green-200);
}

</style>
