import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: "",
    publish_date: new Date(),
    type: "reg"
};

const translation = {
    id: 0,
    the_update_id: "",
    blocks: [],
    sections: [],
    lang: "",
    title: "",
    link_slug: "",
    main_image_url: "",
    sound_url: "",
    publish_state: "",
};

const block = {
    id: 0,
    title: "",
    body: "",
    link: "",
    image: "",
    image_below: "",
    rank: 0,
    type: "",
};

const section = {
    the_update_translation_id: 0,
    section: "",
    rank: 0
};

export default {
    ...tools.models.apiCommons(model, translation, "the_update_id"),
    block: block,
    section: section,
    modelValidation: {
        model: {
            publish_date: {
                validDate: tools.models.validators.PUBLISH_DATE
            },
        }
    },
    translationValidation: {
        model: {
            title: { required },
            link_slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            publish_state: { required },
        }
    },
    uploads: [
        { el: "uImg", target: "main_image_url", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
        { el: "uAudioUrl", target: "sound_url", folder: "/audio/%L/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.SOUND]},
    ]
};
