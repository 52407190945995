export default {
    data(){
        return {
            the_update_sections: [
                { label: 'Attualità', value: 'news' },
                { label: 'Strumenti', value: 'tools' },
            ],
        };
    },
    methods: {
        theUpdateSectionLabel(section){
            let name = '-';
            this.the_update_sections.forEach(s => {
                if (s.value === section) {
                    name = s.label;
                }
            });
            return name;
        }
    }
}