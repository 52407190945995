<template>
    <Panel :toggleable='true' class='col-12 sidebar-like'>
        <template #header>
            Editor delle notizie
        </template>
        <Toolbar class='mt-3'>
            <template #end>
                <SplitButton icon='pi pi-plus' :model='addActions'></SplitButton>
            </template>
        </Toolbar>
        <block v-if='edit && editBlock.id === 0' :block='editBlock' :trans_id='model.id' :thu_id='model.the_update_id'
               @undo='stopEditing' />
        <div :key="'sect_'+model.id+'_'+model.lang+'_'+sec.section" class='grid mt-4'
             v-for='( sec, idx ) in model.sections'>
            <Panel :toggleable='true' class='col-12 the-updates'>
                <template #header>
                    <span>&nbsp;</span>
                    <h2>
                        <template v-if='!blocks(sec.section).length'>
                            <Button style='top: -2px' class='p-button-danger' @click='requireDeleteSection(sec.section)'
                                    icon='pi pi-times' title='Elimina questa sezione' />
                        </template>
                        <template v-if='idx < model.sections.length-1'>
                            <i class='pi pi-caret-down'
                               style='font-size: 2rem'
                               @click='requireSectionMove(sec.section, "down")'
                               title='Sposta sezione in basso'></i>
                        </template>
                        {{ theUpdateSectionLabel(sec.section) }}
                        <template v-if='idx !== 0'>
                            <i class='pi pi-caret-up'
                               style='font-size: 2rem'
                               @click='requireSectionMove(sec.section, "up")'
                               title='Sposta sezione in alto'></i>
                        </template>
                    </h2>
                </template>
                <ul>
                    <li :key="'block_'+model.id+'_'+model.lang+'_'+block.id" v-for='block in blocks(sec.section)'>
                        <div class='grid'>
                            <div class='col-8 md:col-10'>
                                <span class='number'>
                                    <!--
                                                                <SplitButton class='p-button-warning' icon='pi pi-cog'
                                                                             :model='editActions(block)'>
                                                                </SplitButton>
                                    -->
                                    <span class='num' :title='"Rank "+block.rank'>{{ block.num }}.</span>
                                </span>

                                <template v-if='!editBlock || editBlock.id !== block.id '>
                                    <span class='title'>{{ block.title }}</span>
                                    <p>
                                        <span v-html='block.body'></span> <a :href='block.link' v-if='block.link'
                                                                             target='_blank'>Link</a>
                                        <i class='pi pi-copy pointer ml-2' @click='copyText(blockHTML(block))'
                                           title='Copia'></i>
                                    </p>
                                </template>
                                <block v-else-if='editBlock && editBlock.id === block.id' :block='editBlock'
                                       :trans_id='model.id' :thu_id='model.the_update_id' @undo='stopEditing' />


                            </div>
                            <div class='col-4 md:col-2'>
                                <div class='actions'>
                                    <template v-for='butt in editActions(block)' :key='"act_"+block.num+"_"+butt.id'>
                                        <Button :icon='butt.icon'
                                                class='mr-2 mb-2'
                                                :class='{[butt.class]: true}'
                                                @click='butt.command'
                                                :title='butt.label'></Button>
                                    </template>
                                </div>

                            </div>
                        </div>
                    </li>
                </ul>
            </Panel>
        </div>
    </Panel>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters, mapActions } = createNamespacedHelpers('Cont/TheUpdate');
import block from './block';
import Block from './Block';
import Dd_sections from '../../../mixins/dd_sections';
import Notifications from '../../../mixins/notifications';

export default {
    components: {
        Block,
    },
    mixins: [Dd_sections, Notifications],
    data() {
        return {
            edit: false,
            editBlock: null,

        };
    },
    computed: {
        ...mapGetters(['loading']),
        addActions() {
            const actions = [];
            this.the_update_sections.forEach(a => {
                actions.push({
                    label: a.label,
                    command: () => {
                        this.addBlock(a.value);
                    },
                });
            });
            return actions;
        },
    },
    mounted() {
    },
    methods: {
        ...mapActions(['addSection', 'deleteBlock', 'deleteSection', 'moveBlock', 'moveSection']),
        editActions(b) {
            const actions = [];
            actions.push({
                label: 'Modifica',
                id: 'edit',
                icon: 'pi pi-pencil',
                class: "p-button-info",
                command: () => {
                    this.startEditing(b);
                },
            });
            actions.push({
                label: 'Cancella',
                id: 'delete',
                icon: 'pi pi-trash',
                class: "p-button-danger",
                command: () => {
                    this.requireBlockDelete(b);
                },
            });
            if (!b.isFirst) {
                actions.push({
                    label: 'Sposta in alto',
                    icon: 'pi pi-caret-up',
                    class: "p-button-warning",
                    id: 'up',
                    command: () => {
                        this.requireBlockMove(b, 'up');
                    },
                });
            }
            if (!b.isLast) {
                actions.push({
                    label: 'Sposta in basso',
                    id: 'down',
                    class: "p-button-warning",
                    icon: 'pi pi-caret-down',
                    command: () => {
                        this.requireBlockMove(b, 'down');
                    },
                });
            }
            return actions;
        },
        async requireSectionMove(sec, where) {
            this.moveSection({
                section_name: sec,
                thu_id: this.model.the_update_id,
                trans_id: this.model.id,
                where: where,
            })
                .then()
                .catch(err => {
                    this.ShowStandardError(err.response);
                });
        },
        async requireBlockMove(b, where) {
            this.moveBlock({
                block_id: b.id,
                thu_id: this.model.the_update_id,
                trans_id: this.model.id,
                where: where,
            })
                .then()
                .catch(err => {
                    this.ShowStandardError(err.response);
                });
        },
        async requireBlockDelete(b) {
            this.$confirm.require({
                message: 'La cancellazione di questo blocco è irreversibile. Siete sicuri di procedere?',
                header: 'Conferma cancellazione',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when user confirms the action
                    this.deleteBlock({
                        block_id: b.id,
                        thu_id: this.model.the_update_id,
                        trans_id: this.model.id,
                    }).then(() => {
                        this.ShowSuccess('Ottimo', 'Blocco cancellato con successo');
                        this.stopEditing();
                    }).catch(err => {
                        this.ShowStandardError(err.response);
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                },
            });
        },
        requireDeleteSection(s) {
            this.deleteSection({
                section_name: s,
                thu_id: this.model.the_update_id,
                trans_id: this.model.id,
            }).then(() => {
                this.ShowSuccess('Ottimo', 'Sezione eliminata con successo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        startEditing(b) {
            if (!this.edit) {
                this.editBlock = b;
                this.edit = true;
                return;
            }
            this.$confirm.require({
                message: 'Un altro blocco è in stato di modifica. Vuoi scartare le modifiche attuali?',
                header: 'Richiesta di conferma',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    //callback to execute when user confirms the action
                    this.editBlock = b;
                    this.edit = true;
                },
                reject: () => {
                    //callback to execute when user rejects the action
                },
            });

        },
        stopEditing() {
            this.editBlock = null;
            this.edit = false;
        },
        addBlock(type) {
            const newBlock = block.modelCopy();
            newBlock.type = type;

            if (this.sectionExists(type)) {
                this.startEditing(newBlock);
            } else {
                this.addSection({
                    name: type,
                    thu_id: this.model.the_update_id,
                    trans_id: this.model.id,
                }).then(() => {
                    this.startEditing(newBlock);
                }).catch(err => {
                    this.ShowStandardError(err.response);
                });
            }
        },
        blocks(section) {
            const rows = {};
            const copy = [...this.model.blocks];
            let num = this.model.blocks.length + 1;
            this.model.sections.forEach(s => {
                const name = s.section;
                let start = 0;
                if (!rows[name]) rows[name] = [];
                copy.forEach((b, idx) => {
                    if (b.type === name) {
                        rows[name].push({
                            ...b,
                            num: --num,
                            isFirst: start === 0,
                            isLast: false,
                        });
                        start++;
                        delete (copy[idx]);
                    }
                    const len = rows[name].length;
                    if (len > 0) {
                        rows[name][len - 1].last = true;
                    }

                });
            });
            return rows[section];
        },
        sectionExists(type) {
            let found = false;
            if (this.model && this.model.sections) {
                this.model.sections.forEach(section => {
                    if (section.section === type) found = true;
                });
            }
            return found;
        },
        blockHTML(block) {
            let html = '<span class=\'title\'>' + block.title + '</span>';
            html += '<p>';
            html += block.body;
            html += '</p>';
            if (block.link) html += '<a href=\'' + block.link + '\' target=\'_blank\'>Link</a>';
            return html;
        },
    },
    props: {
        model: {
            type: Object,
            default() {
                return {
                    blocks: [],
                    sections: [],
                };
            },
            required: true,
        },
    },
};
</script>

<style lang='scss'>
.actions{
    height: 100%;
    display: flex;
    align-items: center;
}
.the-updates {
    font-family: "Lato", sans-serif;

    .p-panel-header {
        background-color: #FFFFFF !important;
        color: #000 !important;
        text-align: center;

        .pi-plus,
        .pi-minus {
            color: black !important;
        }

        border-left: 0 !important;
        border-right: 0 !important;

        h2 {
            font-size: 34px;
            font-weight: 900;

            i {
                cursor: pointer;
                color: #009688;
            }
        }
    }

    .p-panel-content {
        border-left: 0 !important;
        border-right: 0 !important;
        border-bottom: 0 !important;
    }

    ul {
        margin-left: 130px;

        li {
            list-style: none;
            margin-bottom: 30px;

            .number {
                float: left;
                display: block;
                margin-left: -135px;

                .p-splitbutton {
                    top: -8px;
                    margin-right: 5px;
                }

                .num {
                    font-size: 42px;
                    font-weight: 800;
                    color: rgba(74, 74, 74, 0.4);
                }
            }

            .title {
                color: rgb(74, 74, 74);
                float: left;
                font-size: 20.4px;
                font-weight: 600;
                line-height: 27.2px;
                display: block;
                margin-top: 8.5px;
                margin-bottom: 8.5px;

            }

            p {
                display: block;
                font-size: 17px;
                color: rgb(74, 74, 74);
                font-weight: 400;
                line-height: 25.5px;
                margin-bottom: 17px;
                clear: both;

                a {
                    text-decoration: underline;
                }
            }
        }
    }
}
</style>
