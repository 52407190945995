<template>
    <div v-if='model && !model.is_null' :key='"thu_t_"+lang'>
        <div v-if='lang==="it" && model.id' class='mt-2'>
            <Button icon='pi pi-globe' @click='$emit("translate", model)' label='Traduci automaticamente nelle altre lingue'/>
        </div>
        <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>
        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid mt-5'>
                <div class=' col-12'>
                    <Toolbar class='mt-2'>
                        <template v-slot:start>
                            <div><span class="publish_state">Stato di pubblicazione </span>
                                <Dropdown
                                    v-model="v$.model.publish_state.$model"
                                    :options="publish_states"
                                    optionLabel="label"
                                    optionValue="code"
                                    placeholder="Stato di pubblicazione" /></div>
                        </template>
                    </Toolbar>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-5'>
                <div class='field col-12 md:col-8'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='link_slug'
                                   type='text'
                                   v-model="v$.model.link_slug.$model"
                                   :class="{'p-invalid': v$.model.link_slug.$invalid && v$.model.link_slug.$dirty}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.link_slug = sluggify(model.link_slug)'/>
                        <font-awesome-icon @click='model.link_slug = sluggifyPublishDate()'
                                           class='text-purple-500 hover:text-purple-700 pointer'
                                           title='Estrai dalla data di pubblicazione'
                                           :style='{margin: "0px", fontSize: "35px", border: "1px solid #9C27B0", padding: "4px"}'
                                           :icon="['far', 'clock']"

                        />
                        <Button icon="pi pi-sort-up" class="p-button-info" title='Dal titolo' @click='model.link_slug = sluggify(model.title)'/>
                        <label for='link_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='title'
                                   type='text'
                                   v-model="v$.model.title.$model"
                                   :class="{'p-invalid': v$.model.title.$invalid && v$.model.title.$dirty}"
                        />
                        <label for='title'>Titolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-4'>
                    <uppy-dashboard
                        :uppy='uImg'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='mainImg'
                                   type='text'
                                   v-model="model.main_image_url"
                        />
                        <label for='mainImg'>Immagine principale</label>
                    </span>
                    <div v-if='model.main_image_url' class='text-center'>
                        <img :src='model.main_image_url' alt='Model Image' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12 md:col-4'>
                    <uppy-dashboard
                        :uppy='uAudioUrl'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12 md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='uAudioUrl'
                                   type='text'
                                   v-model="model.sound_url"
                        />
                        <label for='uAudioUrl'>Audio</label>
                    </span>

                    <div v-if='model.sound_url' class='text-center mt-3'>
                        <video controls name='audiourl' :style='{height: "50px", width:"400px"}'>
                            <source type='audio/mpeg' :src='model.sound_url'>
                        </video>
                    </div>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <save-button label='Salva traduzione' @click='save' :load='loading'/>
                </div>
            </div>

            <div class='grid mt-4' v-if='model.id && type === "tpn"'>
                <block-selector :model='model' :parent_date='parent_date' :lang='lang'/>
            </div>

            <div class='grid mt-4' v-if='model.id'>
                <blocks-manager :model='model' :lang='lang' />
            </div>
        </form>
    </div>
</template>

<script>
import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import Uploader from '../../../mixins/uploader';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/TheUpdate');
const commonStore = createNamespacedHelpers('Common');

import useVuelidate from '@vuelidate/core'

import tools from '../../../libs/tools';
import model from './model';

import SaveButton from '../../../components/4books/forms/SaveButton';
import BlocksManager from './BlocksManager';
import BlockSelector from './BlockSelector';

export default {
    data(){
        return {
            parent_date: null
        };
    },
    components: {
        SaveButton, BlocksManager, BlockSelector
    },
    emits: ['translate'],
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.translationValidation;
    },
    beforeUnmount() {
        this.resetUploads(model.uploads);
    },
    computed: {
        ...mapGetters(['row', 'loading']),
        ...commonStore.mapGetters(['languages', 'publish_states']),
        lastUpdateDate() {
            return tools.time.lastUpdate(this.model['last_update']);
        },
        imgRepo(){
            const ret = [];
            if (this.model.repo && this.model.repo.length){
                this.model.repo.split("|").forEach(src => {
                    ret.push({ url: src });
                });
            }
            return ret;
        }
    },
    watch: {
        row(n){
            this.initModel(n);
        },
        lang(){
            this.initModel(this.row);
        },
        dirty(n){
            if (n) this.formIsDirty();
            else this.formIsClean();
        }
    },
    mounted() {
        this.initModel(this.row);
        this.prepareUploads(model.uploads, this.lang);
    },
    methods: {
        ...mapMutations(['formIsDirty', 'formIsClean']),
        ...mapActions(['saveTranslation']),
        reorderedEpisodes(ev){
            this.model.articles = ev.value;
        },
        sluggifyPublishDate(){
            return tools.time.sluggifyIsoDate(this.parent_date, false, this.lang);
        },
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return;
            }

            this.saveTranslation({
                data: model.formatTranslation(this.model),
                parent_id: model.translationParentId(this.model, this.parent_id)
            }).then(() => {
                this.formIsClean();
                this.ShowSuccess('Ottimo', 'Traduzione salvata con successo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initModel(mod){
            this.dirty = false;
            const base = model.newTranslation(this.id, this.lang);
            if (mod && mod.translations){
                let found = false;
                mod.translations.forEach(translation => {
                   if (translation['lang'] === this.lang){
                       this.model = tools.models.fillTranslation(model.parseTranslation(translation), base);
                       this.parent_date = mod.publish_date;
                       found = true;
                       //Init empty arrays
                       if (!this.model.blocks){
                           this.model.blocks = [];
                       }
                       if (!this.model.sections){
                           this.model.sections = [];
                       }
                   }
                });
                if (found) return;
            }
            this.parent_date = new Date();
            this.model = base;
        },
        addArticle(art){
                this.model.articles.push(art)
        },
        removeArticle(art){
            this.model.articles = this.model.articles.filter(b => b.id !== art.id);
        }
    },
    props: {
        lang: {
            type: String,
            default: 'it'
        },
        type: {
            type: String,
            required: true,
            default(){
                return "reg";
            }
        },
        id: {
            type: String
        },
        parent_id: {
            type: String
        }
    },
    mixins: [
        Navigations, Notifications, Uploader
    ]
}
</script>
