<template>
    <div v-if='model && !model.is_null'>
        <Card class='my-1'>
            <template #content>
                <div class='text-right last_update'>Data ultima modifica: {{ lastUpdateDate }}</div>
                <form @submit='save' @change='formIsDirty'>
                    <div class='grid p-fluid formgrid mt-5 flex'>
                        <div class='col-4'>
                            <Dropdown
                                class='ml-5'
                                v-model="model.type"
                                :options="dd_thu_types"
                                optionLabel="label"
                                optionValue="value"
                                placeholder="Tipo" />
                        </div>
                    </div>
                    <div class='grid p-fluid formgrid mt-3 flex'>
                        <div class='col-8 md:col-3 flex align-items-end'>
                            <div class='ml-5'><span class="publish_state">Data di pubblicazione </span>
                                <Calendar
                                    :showTime="true"
                                    inputId="publish_date"
                                    v-model="v$.model.publish_date.$model"
                                    dateFormat="dd/mm/yy"
                                    :class="{'mb-2': true, 'p-invalid': v$.model.publish_date.$invalid && v$.model.publish_date.$dirty}"
                                />
                            </div>
                        </div>

                        <div class='col-4 md:col-3 text-center flex align-items-end'>
                            <save-button @click='save' :load='loading' label='Salva (cambio data)'/>
                        </div>
                    </div>

                </form>
            </template>
        </Card>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/TheUpdate');
import useVuelidate from '@vuelidate/core'

import model from './model';

import Navigations from '../../../mixins/navigations';
import Notifications from '../../../mixins/notifications';
import SaveButton from '../../../components/4books/forms/SaveButton';
import tools from '../../../libs/tools';
import dd_thu_types from '../../../mixins/dd_thu_types';

export default {
    components: { SaveButton },
    data(){
        return {};
    },
    mounted(){
        this.prepare(this.row)
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n){
            this.prepare(n);
        }
    },
    methods: {
        ...mapActions(['savePlain','createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        prepare(row){
            this.model = row;
            if (this.model){
                this.model['publish_date'] = tools.time.api2Date(this.model['publish_date']);
            }
        },
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }

            const save = (this.model.id) ? this.savePlain : this.createPlain;
            const changeRoute = (!this.model.id);

            save(this.model).then( resp => {
                this.formIsClean();
                if (changeRoute){
                    this.navigateToId(resp.id).then( () => {
                        this.ShowSuccess('Ottimo', 'The Update creato con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'The Update modificato con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Navigations,
        Notifications,
        dd_thu_types
    ]
};
</script>
