import tools from '../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: 0,
    title: "",
    body: "",
    link: "",
    image: "",
    image_below: "",
    rank: 0,
    type: ""
};

const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, "the_update_id"),
    modelValidation: {
        model: {
            title: {
                validDate: required
            },
            body: {
                validDate: required
            },
        }
    },
    uploads: [
        { el: "uImg", target: "image", folder: "/images/%Y/%M", protect: false, maxNum: 1, types: [tools.models.uploadFileTypes.IMAGE]},
    ]
};
