<template>
    <Card class='mt-3'>
        <template #title>{{title}}</template>
        <template #content v-if='model' >
            <div class='grid formgrid my-2'>
                <div class=' col-12'>
                        <template v-if='!changeTypeDialog'>
                            <h2>
                                {{theUpdateSectionLabel(model.type)}}
                                <font-awesome-icon
                                    class='text-blue-300 hover:text-blue-400 pointer ml-2'
                                    title='Modifica'
                                    :icon="['fas', 'edit']"
                                    size='1x'
                                    @click='requestTypeChange'
                                />
                            </h2>
                        </template>
                        <template v-else>
                            <div class='grid p-justify-center mb-3'>
                                <div class='field col-12 md:col-3'>
                                    <span class='p-float-label'>
                                        <Dropdown v-model='newType'
                                                  id='type'
                                                  dataKey='value'
                                                  :options='the_update_sections'
                                                  optionLabel='label'
                                                  option-value='value'
                                                  :filter='true'
                                                  filterPlaceholder='Cerca' />
                                        <label for='type'>Tipo</label>
                                    </span>
                                </div>
                                <div class='col-6 text-left'>
                                    <save-button label='Sposta' @click='doTypeChange' :load='loading'/>
                                    <reset-button label='Annulla' :load='loading' class='ml-2' @click='changeTypeDialog=false' />
                                </div>
                            </div>
                        </template>

                </div>
            </div>
            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-9 md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='title'
                                   type='text'
                                   v-model="v$.model.title.$model"
                                   :class="{'p-invalid': v$.model.title.$invalid && v$.model.title.$dirty}"
                        />
                        <label for='title'>Titolo</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12 md:col-10 html-editor-wrap p-float-label'>
                    <HtmlEditor
                        v-model:content="model.body"
                        :key='"cont_thu_block_"+trans_id+"_"+model.id'
                        contentType='html'/>
                    <label>Body</label>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-9  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='link'
                                   type='text'
                                   v-model="model.link"
                        />
                        <label for='link'>Link Url</label>
                    </span>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12  md:col-4'>
                    <uppy-dashboard
                        :uppy='uImg'
                        :props='uppyDashboardProps'
                    />
                </div>
                <div class='field col-12  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='mainImg'
                                   type='text'
                                   v-model="model.image"
                        />
                        <label for='mainImg'>Immagine</label>
                    </span>
                    <div v-if='model.image' class='text-center'>
                        <img :src='model.image' alt='Model Image' :height='uppyDashboardProps.height-30'/>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-9  md:col-6'>
                    <span class='p-float-label'>
                        <InputText id='alt'
                                   type='text'
                                   v-model="model.image_below"
                        />
                        <label for='alt'>Didascalia immagine</label>
                    </span>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <save-button @click='save' :load='loading'/>
                    <reset-button @click='$emit("undo")' :load='loading' class='ml-2'/>
                </div>
            </div>

        </template>
    </Card>
</template>

<script>
import block from './block';
import Dd_sections from '../../../mixins/dd_sections';
import useVuelidate from '@vuelidate/core';
import Uploader from '../../../mixins/uploader';
import SaveButton from '../../../components/4books/forms/SaveButton';
import ResetButton from '../../../components/4books/forms/ResetButton';
import Notifications from '../../../mixins/notifications';

import { createNamespacedHelpers } from 'vuex';
import tools from '../../../libs/tools';
const { mapGetters, mapActions } = createNamespacedHelpers('Cont/TheUpdate');

export default {
    mixins: [Dd_sections, Uploader, Notifications],
    components: {
        SaveButton, ResetButton
    },
    data(){
        return {
            model: null,
            changeTypeDialog: false,
            origType: "",
            newType: ""
        };
    },
    computed: {
        ...mapGetters(['loading']),
        title(){
            if (this.model && this.model.id){
                return "Modifica il blocco";
            }
            return "Inserisci nuovo blocco";
        }
    },
    beforeUnmount() {
        this.resetUploads(block.uploads);
    },
    emits:['undo'],
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return block.modelValidation;
    },
    mounted(){
        this.initModel(this.block);
        //console.log("MOUNTED", this.model);

        this.prepareUploads(block.uploads, '');
    },
    watch: {
        block(n){
            if (n){
                this.initModel(n);
                return;
            }
            this.model = null;
        }
    },
    methods: {
        ...mapActions(['saveBlock', 'deleteBlock']),
        requestTypeChange(){
            this.changeTypeDialog = true;
            this.origType = this.model.type;
            this.newType = this.model.type;
        },
        doTypeChange(){
            if (this.origType === this.newType){
                this.resetTypeChange();
                return;
            }

            //Change new and save
            const newModel = {
                ...this.model,
                id: 0,
                rank: 0,
                type: this.newType
            };

            this.saveBlock({
                block: tools.models.unwrapP(newModel, 'body'),
                thu_id: this.thu_id,
                trans_id: this.trans_id
            }).then(() => {
                this.deleteBlock({
                    block_id: this.model.id,
                    thu_id: this.thu_id,
                    trans_id: this.trans_id,
                }).then(() => {
                    this.ShowSuccess('Ottimo', 'Blocco spostato con successo');
                }).catch(err => {
                    this.ShowStandardError(err.response);
                }).finally(() => {
                    this.$emit('undo');
                });
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
            this.resetTypeChange();
        },
        resetTypeChange(){
            this.changeTypeDialog = false;
            this.origType = "";
            this.newType = "";
        },
        async save(){
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return;
            }


            this.saveBlock({
                block: tools.models.unwrapP(this.model, 'body'),
                thu_id: this.thu_id,
                trans_id: this.trans_id
            }).then(() => {
                this.ShowSuccess('Ottimo', 'Blocco salvato con successo');
                this.$emit('undo');
            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
        initModel(m){
            this.model = {
                ...m
            }
            if (this.model.body && !this.model.body.startsWith("<p>")){
                this.model.body = "<p>" + m.body + "</p>";
            }
        }
    },
    props: {
        block: {
            required: true,
            type: Object,
            default(){
                return block.modelCopy();
            }
        },
        trans_id: {
            required: true,
            type: Number
        },
        thu_id: {
            required: true,
            type: String
        }
    }
}
</script>
