<template>
    <Card>
        <template #title>
            <h5 v-if='id === 0'>Nuovo record</h5>
            <h5 v-else>{{ pageTitle }} {{ languageString }}
                <Flag v-if='lang' :code="lang" :name="language" :width='24' :class="{'mr-1': true}"/>
            </h5>
        </template>
        <template #content v-if='row'>
            <PlainForm />
            <Card class='my-2'>
                <template #content>
                    <TabMenu v-if='id !== ""' :model="tabMenus" v-model:activeIndex='activeIndex' />
                    <TranslatedForm v-if='lang && id !== ""'
                                    :lang='lang'
                                    :id='row.id'
                                    :parent_id='id'
                                    :type='row.type'
                                    @translate='requestTranslation($event)'/>
                </template>
            </Card>
        </template>
    </Card>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters, mapMutations } = createNamespacedHelpers('Cont/TheUpdate');
const commonStore = createNamespacedHelpers('Common');

import Factory from './model';
import Notifications from '../../../mixins/notifications';
import Navigations from '../../../mixins/navigations';
import PageGuards from '../../../mixins/guards';

import PlainForm from './Plain';
import TranslatedForm from './Translated';
import tools from '../../../libs/tools';

export default {
    beforeRouteLeave(to, from, next){
      this.beforeRouteLeaveCheck(to, from, next);
    },
    beforeRouteUpdate(to, from, next){
        this.beforeRouteUpdateCheck(to, from, next);
    },
    mounted(){
        this.loadData(this.id);
    },
    computed: {
        ...mapGetters(['row', 'loading', 'dirtyForm']),
        ...commonStore.mapGetters(['languages']),
    },
    methods: {
        ...mapActions(['fetchOne', 'autoTranslate']),
        ...mapMutations(['setRow', 'formIsClean']),
        loadData(id){
            const bp = this.$route['meta']['basePath'] || '';
            this.tabMenus = Factory.translationsTabs(bp, id, this.languages);
            if (id === ""){
                this.setPageTitle("Crea un nuovo The Update");
                const m = Factory.modelCopy();
                const translations = [];
                if (this.languages) {
                    Object.keys(this.languages).forEach(k => {
                        translations.push(Factory.newTranslation("", k));
                    });
                }
                m['translations'] = translations;

                this.setRow(m);
            } else {
                this.fetchOne(id).then(res => {
                    this.setPageTitle("Modifica il The Update del " + tools.time.dateFormat(res['publish_date']));
                }).catch(err => {
                    this.ShowStandardError(err.response);
                }).finally(() => {});
            }

        },
        requestTranslation(ev){
            if (!this.countExistingTranslationsExcluding(this.lang)){
                //safe
                this.doRequestTranslation(ev);
            } else {
                //unsafe
                if (this.countExistingTranslationsExcluding(this.lang, true)){
                    //Forbidden
                    this.ShowError("Errore", "Verrebbero sovrascritte traduzioni che sono *Published*");
                } else {
                    //Confirm
                    this.$confirm.require({
                        message: 'Sono già presenti delle traduzioni che verranno interamente sovrascritte. Vuoi procedere lo stesso?',
                        header: 'Attenzione',
                        icon: 'pi pi-exclamation-triangle',
                        accept: () => {
                            this.doRequestTranslation(ev);
                        },
                        reject: () => {}
                    });
                }
            }
        },
        doRequestTranslation(ev){
            this.autoTranslate({
                thu_id: ev.the_update_id,
                trans_id: ev.id
            })
                .then(() => {
                    this.ShowSuccess("Fatto", "La traduzione automatica è stata inserita")
                })
                .catch(err => {
                    this.ShowStandardError(err.response);
                });
        },
        countExistingTranslationsExcluding(lang, published = false){
            if (!this.row) return 0;
            if (!this.row.translations) return 0;
            let count = 0;
            this.row.translations.forEach( t => {
                if (t.lang !== lang){
                    if (published){
                        if (t.id && t.publish_state === "published") count++;
                    } else {
                        if (t.id) count++;
                    }

                }
            })
            return count;
        }
    },
    props: {
        id: {
            default: "",
            type: String
        },
        lang: {
            default: "",
            type: String
        }
    },
    watch: {
        id(n, o){
            if (n !== o) {
                this.loadData(n);
            }
        }
    },
    mixins: [
        Notifications,
        Navigations,
        PageGuards
    ],
    components: {
        PlainForm,
        TranslatedForm
    }
}
</script>
